import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = {
  primary: {
    color: "",
    contrast: "",
  },
  secondary: {
    color: "",
    contrast: "",
  },
  warning: {
    color: "",
    contrast: "",
  },
  error: {
    color: "",
    contrast: "",
  },
  common: {
    black: "#333",
    white: "#ffffff",
    gray: "#ddd",
  },
  background: {
    black: "#333",
    lightgray: "#585858",
  },
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

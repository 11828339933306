import styled from "styled-components";
import logo from "../logo2_64.png";

interface INavItem {
  float?: string;
}

const Nav = styled.div`
  background-color: ${(p) => p.theme.background.black};
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
`;

const NavItem = styled.a<INavItem>`
  float: ${(props) => props?.float ?? "right"};
  color: ${(p) => p.theme.common.white};
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  &:hover {
    background-color: ${(p) => p.theme.common.gray};
    color: ${(p) => p.theme.common.white};
  }
  &:active {
    background-color: ${(p) => p.theme.common.gray};
    color: ${(p) => p.theme.common.white};
  }
`;

export const Header = () => {
  return (
    <Nav>
      <NavItem href="#hero" float="left">
        <img src={logo} />
      </NavItem>
      <NavItem href="#howitworks">How It Works</NavItem>
      <NavItem href="#samples">Samples and Pricing</NavItem>
    </Nav>
  );
};

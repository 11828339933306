import styled from "styled-components";
import "./App.css";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { ContactForm } from "./components/contactform";
import { AudioPlayer } from "./components/audioplayer";

const ParentContainer = styled.div`
  background-color: ${(p) => p.theme.background.black};
  color: ${(p) => p.theme.common.white};
  padding-top: 100px;
`;

const Container = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;

interface ISection {
  align?: string;
  minHeight?: number;
}

const Section = styled.div<ISection>`
  min-height: ${(p) => p?.minHeight ?? 400}px;
  text-align: ${(p) => p.align};
  padding-top: 30px;
`;

export interface IFlatDiv {
  gap?: string;
  marginLeft?: string;
  marginRight?: string;
}

export const FlatDiv = styled.div<IFlatDiv>`
  display: flex;
  gap: ${(p) => p?.gap ?? "16px"};
  margin-right: ${(p) => p?.marginRight ?? 0};
  margin-left: ${(p) => p?.marginLeft ?? 0};
  @media screen and (max-width: 920px) {
    flex-wrap: wrap;
  }
`;

export const FlatDivCol = styled.div``;

export const ProcessHeading = styled.h5`
  border-bottom: solid 1px ${(p) => p.theme.white};
  margin-bottom: 5px;
  height: 30px;
`;

interface ICustomHeading {
  fontSize: number;
}

const CustomHeading = styled.div<ICustomHeading>`
  font-size: ${(p) => p.fontSize}px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
`;

function App() {
  const sample = require("./sample.mp3");
  const sample2 = require("./sample2.mp3");
  return (
    <ParentContainer>
      <Header />
      <Container>
        <Section id="hero" align="center">
          <div>
            <CustomHeading fontSize={64}> AudioBook Bot </CustomHeading>
            <p>
              Use AudioBook Bot to create a production level audiobook for a
              fraction of the time and cost. Fill out the form below and get
              started today! Beep Boop.
            </p>
          </div>
          <br></br>
          <ContactForm />
        </Section>
        <Section id="about">
          <h2>How Does AudioBook Bot Work?</h2>
          <p>
            Audiobook bot uses generative ai to convert text to speech. With
            some additional annotations, it can provide your book with a whole
            cast of characters. We can also narrate the book in your own voice
            with a minimal sample.
          </p>
          <h4>The Process</h4>
          <FlatDiv>
            <FlatDivCol style={{ maxWidth: 200 }}>
              <ProcessHeading>1. Sample Gathering</ProcessHeading>
              You give us a character sample of each character you want to have
              a unique voice. We have over 120 voices to choose from.
            </FlatDivCol>
            <FlatDivCol>
              <ProcessHeading>2. Sample Creation</ProcessHeading>
              We generate some samples for you to listen and make a decision on
              the different voices.
            </FlatDivCol>
            <FlatDivCol>
              <ProcessHeading>
                3. Annotation and First Chapter Sample
              </ProcessHeading>
              We annotate the book and create a larger sample of the first
              chapter of the book.
            </FlatDivCol>
            <FlatDivCol>
              <ProcessHeading>4. Create Book</ProcessHeading>
              Voila! Your book is created!
            </FlatDivCol>
            <FlatDivCol>
              <ProcessHeading>5. Post Processing</ProcessHeading>
              You listen to the audiobook and tell us what needs to be fixed.
            </FlatDivCol>
          </FlatDiv>
        </Section>
        <Section id="samples">
          <CustomHeading fontSize={24}>Some Samples </CustomHeading>
          <div>
            Below are some samples from a short story called Malden's Test. The
            stories were all written by Wilson (creator of Audiobook Bot) and
            all generated by AudioBook Bot.
          </div>
          <FlatDiv>
            <FlatDivCol>
              <ProcessHeading>
                Chapter 1, Maldan, Shamara, narrator
              </ProcessHeading>
              <AudioPlayer src={sample} tooltip="Sample 1" />
            </FlatDivCol>
            <FlatDivCol>
              <ProcessHeading>
                Chapter 1, Maldan, Shamara, narrator, different voices
              </ProcessHeading>
              <AudioPlayer src={sample2} tooltip="Sample 2" />
            </FlatDivCol>
          </FlatDiv>
          <br></br>
          <br></br>
          <CustomHeading fontSize={24}>Our Pricing</CustomHeading>
          Our pricing is simple, $100 for every 100,000 characters (~around 2
          hours of audio). We provide a money back guarantee if you're not happy
          with the results. Still interested? Fill out the &nbsp;
          <a style={{ color: "#ff3366" }} href="#hero">
            form above!
          </a>
        </Section>
        <Footer />
      </Container>
    </ParentContainer>
  );
}

export default App;

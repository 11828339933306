import styled from "styled-components";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { isEmailValid } from "../util";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";

const EmailContainer = styled.div`
  width: 75%;
  display: inline-flex;
  flex-direction: column;
`;

const Input = styled.input`
  border-radius: 7px;
  padding: 16px;
  width: 100%;
  margin-top: 5px;
`;

const SendButton = styled.button`
  display: inline-block;
  padding: 16px;
  border-radius: 7px;
  background-color: #ff3366;
  color: white;
  width: 100%;
  cursor: pointer;
  margin-top: 5px;
  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;

const FormLabel = styled.label`
  font-size: 16px;
  padding-right: 16px;
  width: 120px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  width: 100%;
`;

export const ContactForm = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const onClick = async () => {
    if (!isEmailValid(email)) {
      toast.error("Please input a valid email address.", {
        position: "top-right",
      });
      return;
    }

    var templateParams = {
      name: name,
      email: email,
    };

    const response = await toast.promise(
      emailjs.send("service_b5kwjhc", "template_dsi7inr", templateParams, {
        publicKey: "c2ErvFQraL7EEFc2T",
      }),
      {
        pending: "Sending",
        success: "Thank you for reaching out, we will be in touch shortly.",
        error: "An error occured, please try again later.",
      }
    );

    clear();
  };

  const clear = () => {
    setEmail("");
    setName("");
  };

  return (
    <EmailContainer>
      <Container>
        <FormLabel>Name:</FormLabel>
        <Input
          type="text"
          placeholder="John Smith"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </Container>
      <Container>
        <FormLabel>Email: </FormLabel>
        <Input
          type="email"
          placeholder="johnsmith@email.com"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </Container>
      <SendButton onClick={onClick} disabled={!email || !name}>
        Reach Out
      </SendButton>
      <ToastContainer />
    </EmailContainer>
  );
};

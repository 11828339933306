import styled from "styled-components";

export interface IAudoiPlayer {
  src: string;
  tooltip: string;
}

const Audio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AudioPlayer = ({ src, tooltip }: IAudoiPlayer) => {
  return (
    <Audio>
      <audio src={src} title={tooltip} controls />
    </Audio>
  );
};

export const isEmailValid = (email?: string) => {
  if (!email) return false;

  const parts = email.split("@");
  if (parts.length != 2) return false;
  if (parts[0].length == 0) return false;

  const subParts = parts[1].split(".");
  if (subParts.length < 2) return false;
  subParts.forEach((element) => {
    if (element.length == 0) {
      return false;
    }
  });

  return true;
};

import styled from "styled-components";

const FooterParentContainer = styled.div`
  border-top: solid 1px ${(props) => props.theme.common.white};
  text-align: center;
`;

const FooterContainer = styled.div`
  color: ${(p) => p.theme.common.white};
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Footer = () => {
  return (
    <FooterParentContainer>
      <FooterContainer>
        AudioBookBot &copy; {new Date().getFullYear()}
      </FooterContainer>
    </FooterParentContainer>
  );
};
